<template>
  <div
    id="contents"
    class="status_board_osung"
    :class="{
      status_board: tabIndex == 0 || tabIndex == 1,
      plc_monitor: tabIndex == 0 || tabIndex == 1,
      plc_th_status: tabIndex == 0,
      plc_metal_status: tabIndex == 1,
      weight_scale: tabIndex == 2,
      default_setting: tabIndex == 3,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="asideIndex"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(aside, index) in asideIndex"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a @click="SetOpenTabIndex(index)">{{ aside.title }}</a>
          </li>
        </ul>
      </div>
      <TempRealtimeForm v-if="tabIndex == 0" />
      <CounterRealtimeForm v-if="tabIndex == 1" />
      <WeightScaleForm v-if="tabIndex == 2" />
      <DefaultPlcForm v-if="tabIndex == 3" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import TempRealtimeForm from '@/views/forms/Custom/Monitor/20/TempRealtimeForm';
import CounterRealtimeForm from '@/views/forms/Custom/Monitor/20/CounterRealtimeForm';
import WeightScaleForm from '@/views/forms/Custom/Monitor/20/WeightScaleForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/20/DefaultPlcForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    TempRealtimeForm,
    CounterRealtimeForm,
    WeightScaleForm,
    DefaultPlcForm,
  },
  data() {
    return {
      asideIndex: [
        { title: '실시간 온습도 현황', name: 'real_time_status' },
        { title: '실시간 포장기 현황', name: 'counter_time_status' },
        { title: '중량계', name: 'weight_scale' },
        { title: '기준값 설정', name: 'default_setting' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'osung_monitoring';
</style>
